<template>
   <!-- 出库单 -->
   <div 
    class="Box"
    v-loading.fullscreen.lock="loading"
    element-loading-text="请求数据中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
   >
     <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
       <el-tab-pane label="所有状态" name="all"></el-tab-pane>
       <el-tab-pane :label="'待提交'+`(${waitSubmitCount})`" name="toSubmit"></el-tab-pane>
       <el-tab-pane :label="'待出库'+`(${waitOutCount})`" name="toOut"></el-tab-pane>
       <el-tab-pane label="已完成" name="finished"></el-tab-pane>
       <el-tab-pane label="已撤销" name="revoked"></el-tab-pane>
      </el-tabs>
     <!-- 筛选条件 -->
     <div style="margin-top: 20px">
       <el-row>
         <el-select 
           clearable
           placeholder="创建时间" 
           v-model="pageInfo.timeType" 
           style="margin-right: 15px"
         >
           <el-option 
            v-for="item in searchTimeTypeList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
           ></el-option>
         </el-select>
         <el-date-picker
          style="margin-right:10px; position:relative; top:1px"
          v-model="datetime"
          type="daterange"
          format="yyyy年MM月dd日"
          value-format="yyyy-MM-dd"
          range-separator="至"
          :picker-options="pickerOptions"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
         </el-date-picker>
         <el-select 
            clearable
            placeholder="入库类型" 
            style="margin-right: 15px"
            v-model="pageInfo.storageType" 
           >
            <el-option 
              v-for="item in outTypeList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
         </el-select>
         <el-select 
            clearable
            placeholder="全部仓库" 
            v-model="pageInfo.storageId" 
            style="margin-right: 15px"
           >
            <el-option
             v-for="item in storageIdList"
             :key="item.id"
             :label="item.value"
             :value="item.id"
           ></el-option>
         </el-select>
         <el-input v-model="pageInfo.keyword" placeholder="搜索内容" style="width:300px; margin-right:10px">
            <el-select style="width:120px" slot="prepend" v-model="pageInfo.searchType" placeholder="入库单号">
              <el-option
               v-for="item in searchTypeList"
               :key="item.id"
               :label="item.value"
               :value="item.id"
              ></el-option>
            </el-select>
         </el-input>
         <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
         <el-button type="primary" icon="el-icon-search" @click="handleSearch(tabName)">查询</el-button>
       </el-row>
     </div>
     <!-- 按钮区域 -->
      <!-- 全部 -->
      <div style="margin-top: 15px" v-show="activeName=='all'?true:false">   
        <el-button type="primary" @click="handleOutbound">添加出库单</el-button>
        <el-dropdown style="margin: 0 15px" @command="handleCommand1">
          <el-button type="primary">
            批量操作<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="0">批量提交</el-dropdown-item>
            <el-dropdown-item command="1">批量确认出库</el-dropdown-item>
            <el-dropdown-item command="2">批量提交并出库</el-dropdown-item>
            <el-dropdown-item command="3">批量删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button @click="handlePrintOrder('')">批量打印出库单</el-button>
      </div>
      <!-- 待提交 -->
      <div style="margin-top: 15px" v-show="activeName=='toSubmit'?true:false">
       <el-button type="primary" @click="handleToSubmit('submit')">批量提交</el-button>
       <el-button type="primary" @click="handleToSubmit('submitAndOut')">批量提交并出库</el-button>
       <el-button @click="handleToSubmit('delete')">批量删除</el-button>
       <el-button @click="handlePrintOrder('toSubmit')">批量打印出库单</el-button>
      </div>
      <!-- 待出库 -->
      <div style="margin-top: 15px" v-show="activeName=='toOut'?true:false">
       <el-button type="primary" @click="handleBatchOut('out')">批量确认出库</el-button>
       <el-button @click="handleBatchOut('delete')">批量删除</el-button>
       <el-button @click="handlePrintOrder('toOut')">批量打印出库单</el-button>
      </div>
      <!-- 已完成/已撤销 -->
      <div style="margin-top: 15px" v-show="activeName=='finished'?true:false">
       <el-button @click="handlePrintOrder('finished')">批量打印出库单</el-button>
      </div>
      <div style="margin-top: 15px" v-show="activeName=='revoked'?true:false">
        <el-button @click="handlePrintOrder('revoked')">批量打印出库单</el-button>
      </div>
     </div>

     <div style="flex:1; overflow: auto; padding-top: 40px" class="content">
       <!-- 表格区域 -->
        <el-table
         border
         v-loading='loading'
         height="100%"
         class="outTable"
         :span-method="arraySpanMethod"
         @selection-change="handleSelectionChange"
         :data="tableList"
         style="width: 100%; border-top:1px solid #EBEEF5" 
         :cell-style="{ fontSize: '13px', padding: '7px 0' }"
         :header-cell-style="{
           fontSize: '13px',
           padding: '9px 0',
           marginTop: '10px',
         }"
         row-key="childId"
         default-expand-all
         :tree-props="{children: 'itemList'}"
        >
         <el-table-column type="selection" width="width"></el-table-column>
         <el-table-column label="图片" prop="prop" align="center">
             <template slot-scope="scope">
               <div v-if="scope.row.itemList!=undefined" class="tableMenu">
                 <!-- 编码区域 -->
                 <div>
                   <CopyButton :content="scope.row.obNo">
                    <el-button slot="copy" type="text">{{scope.row.obNo}}</el-button> 
                   </CopyButton>
                 </div>
                 <!-- 出库仓库 -->
                 <div>
                    <span>出库仓库：</span>
                    <span>{{scope.row.outStorageName}}</span>
                 </div>
                 <!-- 类型 -->
                 <div>
                   <span>类型：</span>
                   <span>{{scope.row.typeName}}</span>
                 </div>
                 <!-- 创建人/创建时间 -->
                 <div>
                   <span>创建人/创建时间：</span>
                   <span>{{scope.row.createByName}}/{{scope.row.createTime}}</span>
                 </div>
                 <!-- 备注 -->
                 <div>
                   <span>备注：</span>
                   <span>{{scope.row.tips}}</span>
                   <el-button style="margin-left: 6px" type="text" class="el-icon-edit" @click="handleUpdateTip(scope.row)"></el-button>
                 </div>
                 <div style="flex:1;text-align:right;padding-right:20px;">
                    <span v-if="scope.row.status=='toSubmit'" class="status green">待提交</span>
                    <span v-if="scope.row.status=='toOut'" class="status orange">待出库</span>
                    <span v-if="scope.row.status=='finished'" class="status">已完成</span>
                    <span v-if="scope.row.status=='revoked'" class="status red">已撤销</span>
                    <el-button type="text" style="margin:-2px 15px 0" @click="getDetail(scope.row.id)">详情</el-button>
                    <el-dropdown trigger="click" placement='bottom'>
                      <el-button type="text">操作<i class="el-icon-arrow-down"></i></el-button>
                        <el-dropdown-menu slot="dropdown">
                          <!-- 待提交 -->
                          <template v-if="scope.row.status=='toSubmit'">   
                            <el-dropdown-item @click.native="handleBatchUpdate('submit',scope.row.obNo,scope.row.id)">提交</el-dropdown-item>
                            <el-dropdown-item @click.native="handleBatchUpdate('submitAndOut',scope.row.obNo,scope.row.id)">提交并出库</el-dropdown-item>
                            <el-dropdown-item @click.native="getUpdateInit(scope.row.id)">编辑</el-dropdown-item>
                            <el-dropdown-item @click.native="handleBatchUpdate('delete',scope.row.obNo,scope.row.id)">删除</el-dropdown-item>
                            <el-dropdown-item @click.native="handlePrint(scope.row.id)">打印</el-dropdown-item>
                          </template>
                          <!-- 待出库 -->
                          <template v-else-if="scope.row.status=='toOut'">
                            <el-dropdown-item @click.native="handleBatchUpdate('out',scope.row.obNo,scope.row.id)">确认出库</el-dropdown-item>
                            <el-dropdown-item @click.native="handleBatchUpdate('delete',scope.row.obNo,scope.row.id)">删除</el-dropdown-item>
                            <el-dropdown-item @click.native="handlePrint(scope.row.id)">打印</el-dropdown-item>
                          </template>
                          <!-- 已完成 -->
                          <template v-else-if="scope.row.status=='finished'">
                            <el-dropdown-item @click.native="handleBatchUpdate('costCollection',scope.row.obNo,scope.row.id)">成本补录</el-dropdown-item>
                            <el-dropdown-item @click.native="handleBatchUpdate('revoke',scope.row.obNo,scope.row.id)">撤销</el-dropdown-item>
                            <el-dropdown-item @click.native="handleBatchUpdate(scope.row.obNo,scope.row.id)">打印</el-dropdown-item>
                          </template>
                          <!-- 已撤销 -->
                          <template v-else-if="scope.row.status=='revoked'">
                            <el-dropdown-item>打印</el-dropdown-item>
                          </template>
                        </el-dropdown-menu>
                    </el-dropdown>
                 </div>
               </div>
               <div v-else style="">
                 <Picture :url='scope.row.imageUrl' :width='40'></Picture>
               </div>
             </template>
         </el-table-column>
         <el-table-column label="品名/SKU" align="center">
             <template v-if="scope.row.itemList==undefined" slot-scope="scope">
               <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
               <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
             </template>
         </el-table-column>
         <el-table-column label="FNSKU/店铺" prop="prop" align="center"></el-table-column>
         <el-table-column label="可用出库量" prop="" align="center">
             <template v-if="scope.row.itemList==undefined" slot-scope="scope">
               <p :title="scope.row.goodQuantity" class="oneline">{{scope.row.goodQuantity}}</p>
             </template>
         </el-table-column>
         <el-table-column label="次品出库量" prop="" align="center">
             <template slot-scope="scope">
               <p :title="scope.row.badQuantity" class="oneline">{{scope.row.badQuantity}}</p>
             </template>
         </el-table-column>
         <el-table-column label="待发货量" prop="" align="center"></el-table-column>
         <el-table-column label="操作" align="center"></el-table-column>
       </el-table>
     </div>
     <div style="text-align:center;border-top:1px solid #E4E7ED;padding:10px">
       <el-pagination
         @size-change="handleSizeChange"
         @current-change="handleCurrentChange"
         layout="total, sizes, prev, pager, next, jumper"
         :page-sizes="[20, 30, 50, 100]"
         :total="total"
         :page-size="pageInfo.pageSize"
         :current-page="pageInfo.current"
         style="text-align: center; margin-top: 15px"
       ></el-pagination>
     </div>
     <!-- 添加出库单 -->
     <el-dialog
       width="60%"
       v-loading='dialogLoading'
       :visible.sync="addOutOrderVisible"
     >
         <div slot="title" class="header-title">
           <el-row v-if="title=='0'">添加出库单</el-row>
           <el-row type="flex" justify="start" v-if="title=='1'">
            <h3 style="margin-right:20px">{{editDetail.obNo}}</h3>
            <span v-show="editDetail.status=='toSubmit'" class="status red">待提交</span>
            <span v-show="editDetail.status=='toOut'" class="status red">待出库</span>
            <span v-show="editDetail.status=='finished'" class="status red">已完成</span>
            <span v-show="editDetail.status=='revoked'" class="status red">已撤回</span>
           </el-row>
         </div>
         <el-form :model="formDataList">
          <!-- 第一行 -->
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item>
                <span slot="label">
                  <span style="color: red">*</span>出库仓库
                </span>
                <el-select 
                 clearable
                 placeholder="请选择出库仓库" 
                 v-model="formDataList.outboundId"
                 @change="changeStorage"
                >
                  <el-option
                   v-for="item in storageList"
                   :key="item.id"
                   :label="item.value"
                   :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <span slot="label">
                  <span style="color: red">*</span>出库类型
                </span>
                <el-select 
                 clearable
                 placeholder="请选择出库类型"
                 v-model="formDataList.outboundType" 
                >
                 <el-option
                   v-for="item in outTypeList"
                   :key="item.id"
                   :label="item.value"
                   :value="item.id"
                 ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6" v-show="formDataList.outboundType==''||formDataList.outboundType=='FBAOut'||formDataList.outboundType=='otherOut'?true:false">
               <el-form-item label="目的仓库">
                 <el-select 
                  clearable
                  placeholder="请选择目的仓库"
                  v-model="formDataList.toStorageId" 
                 >
                  <el-option
                    v-for="item in toWarehouseList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                 </el-select>
               </el-form-item>
            </el-col>
            <el-col :span="6" v-show="formDataList.outboundType=='returnOut'?true:false">
               <el-form-item>
                 <span slot="label">
                  <span style="color: red">*</span>供应商
                 </span>
                 <el-select placeholder="请选择供应商" v-model="formDataList.supplierId">
                   <el-option
                    v-for="item in supplierList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                   ></el-option>
                 </el-select>
               </el-form-item>
            </el-col>
            <el-col :span="6" v-show="formDataList.outboundType=='returnOut'?true:false">
              <el-form-item>
                <span slot="label">采购单号
                 <el-tooltip placement="top">
                   <div slot="content" style="width: 90px">
                     填入采购单号, 系统将溯源采购单价, 并将退货数量自动同步至采购单详情
                   </div>
                   <i class="iconfont icon-wenhao"></i>
                 </el-tooltip>
                </span>
                <el-input :disabled="true" style="width: 60%" v-model="formDataList.purchaseOrder"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 第二行 -->
          <el-row :gutter="20" v-show="formDataList.outboundType=='returnOut'?true:false">
            <el-col :span="6">
              <el-form-item>
                <span slot="label">退货运费(¥)
                  <el-tooltip placement="top">
                    <div slot="content" style="width: 90px">
                      若退货运费由我方付请在此处记录
                    </div>
                    <i class="iconfont icon-wenhao"></i>
                  </el-tooltip>
                </span>
                <el-input style="width: 60%" placeholder="退货运费" v-model="formDataList.returnFreight"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
               <el-form-item label="其他费用(¥)">
                 <el-input style="width: 60%" placeholder="其他费用" v-model="formDataList.otherFreight"></el-input>
               </el-form-item>
            </el-col>
            <el-col :span="6">
               <el-form-item>
                 <span slot="label">
                   <span style="color: red">*</span>费用分配方式
                   <el-tooltip slot="content" style="width: 90px">
                     <div>
                       选中按金额或按数量分配后, 退货费用(运费, 按其他费用)按照采购单算入库存成本, 选中不分配则不计算
                     </div>
                     <i class="iconfont icon-wenhao"></i>
                   </el-tooltip>
                 </span>
                 <el-select placeholder="费用分配方式" v-model="formDataList.allocation">
                   <el-option
                    v-for="item in costAllocationList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                   ></el-option>
                 </el-select>
               </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <span slot="label">
                  <span style="color: red">*</span>采购员
                </span>
                <el-select placeholder="采购员" v-model="formDataList.buyer">
                  <el-option
                   v-for="item in buyerList"
                   :key="item.id"
                   :label="item.userName"
                   :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
           <!-- 第三行 -->
           <el-row>
             <el-form-item label="备注：">
              <el-input style="width: 60%" type="textarea" :rows="2" placeholder="请输入备注" v-model="formDataList.remarks"></el-input>
             </el-form-item>
           </el-row>
           <!-- 添加商品 -->
           <el-row style="margin: 20px 0">   
             <el-button icon="el-icon-circle-plus-outline" type="primary" @click="handleAddProduct">添加商品</el-button>
             <el-button icon="el-icon-upload" @click="handleImport">导入产品</el-button>
           </el-row>
           <el-table
            border
            :data="productList"
            header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
           >
             <el-table-column label="图片"  align="center">
               <template slot-scope="scope"> 
                <div class="noPic">
                  <span v-if="!scope.row.imageUrl">暂无图片</span>
                  <img :src="scope.row.imageUrl" alt="" width="100%" height="100%">
                </div>
               </template>
             </el-table-column>
             <el-table-column label="商品名称" prop="title" align="center" :show-overflow-tooltip="true"></el-table-column>
             <el-table-column label="SKU" prop="sku" align="center"></el-table-column>
             <el-table-column label="FnSku" prop="" align="center"></el-table-column>
             <el-table-column label="可用量" prop="goodQuantity" align="center"></el-table-column>
             <el-table-column label="可用出库量" align="center">
               <template slot-scope="scope">
                 <el-row type="flex" justify="start">
                   <el-input v-model="scope.row.available"></el-input>
                    <el-tooltip effect="dark" content="可用出库量需小于可用量" placement="top">
                       <i v-show="parseInt(scope.row.available)>scope.row.goodQuantity?true:false" style="margin: 6px 0 0 6px; color: red" class="el-icon-warning-outline"></i>
                    </el-tooltip>
                 </el-row>
               </template>
             </el-table-column>
             <el-table-column label="次品量" prop="badQuantity" align="center"></el-table-column>
             <el-table-column label="次品出库量" align="center">
               <template slot-scope="scope">
                 <el-row type="flex" justify="start">
                   <el-input v-model="scope.row.delfective"></el-input>
                   <el-tooltip effect="dark" content="次品出库量需小于次品量" placement="top">
                     <i v-show="parseInt(scope.row.delfective)>scope.row.badQuantity?true:false" style="margin: 6px 0 0 6px; color: red" class="el-icon-warning-outline"></i>
                   </el-tooltip>
                 </el-row>
               </template>
             </el-table-column>
             <el-table-column label="操作" align="center">
               <template slot-scope="scope">
                 <el-link type="primary" @click="handleRemove(scope.$index)">移除</el-link>
               </template>
             </el-table-column>
           </el-table>
           <el-form-item>
            <el-row v-if="type=='1'" type="flex" justify="end" style="margin-top: 20px">
              <el-button @click="addOutOrderVisible=false">取消</el-button>
              <el-button @click="handleSubmit('save', 1)">暂存</el-button>
              <el-button type="primary" @click="handleSubmit('submit', 1)">提交</el-button>
              <el-button type="primary" @click="handleSubmit('submitAndOut', 1)">提交并出库</el-button>
            </el-row>
            <el-row v-else-if="type=='2'" type="flex" justify="end" style="margin-top: 20px">
              <el-button @click="addOutOrderVisible=false">取消</el-button>
              <el-button @click="handleSubmit('save', 2)">暂存</el-button>
              <el-button type="primary" @click="handleSubmit('submit', 2)">提交</el-button>
              <el-button type="primary" @click="handleSubmit('submitAndOut', 2)">提交并出库</el-button>
            </el-row>
           </el-form-item>
         </el-form>
     </el-dialog>

     <!-- 添加商品 -->
     <el-dialog
       width="40%"
       title="添加商品"
       :visible.sync="addGoodVisible"
     >
       <el-row type="flex" justify="start" style="margin-bottom: 20px">
         <el-input 
          clearable
          @clear="getProductList"
          v-model="pageList.keyword"
          style="width: 20%; margin-right: 15px"
          placeholder="请输入品名/SKU/FNSKU"
         ></el-input>
         <el-button 
          type="primary" 
          icon="el-icon-search"
          @click="getProductList"
         >查询</el-button>
       </el-row>
       <el-table
        border
        height="600"
        row-key="productId"
        ref="multipleTable"
        :data="productDataList"
        @selection-change="handleSelectionProduct"
        header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
       > 
         <el-table-column type="selection" width="55" :reserve-selection="true" align="center"></el-table-column>
         <el-table-column label="图片" align="center">
           <template slot-scope="scope"> 
            <div class="noPic">
              <span v-if="!scope.row.imageUrl">暂无图片</span>
              <img :src="scope.row.imageUrl" alt="" width="100%" height="100%">
            </div>
           </template>
         </el-table-column>
         <el-table-column label="商品名称" prop="title" align="center" :show-overflow-tooltip="true"></el-table-column>
         <el-table-column label="SKU" prop="sku" align="center"></el-table-column>
         <el-table-column label="Fnsku" prop="" align="center"></el-table-column>
         <el-table-column label="仓库" prop="storageName" align="center"></el-table-column>
         <el-table-column label="良品量" prop="goodQuantity" align="center"></el-table-column>
         <el-table-column label="次品量" prop="badQuantity" align="center"></el-table-column>
       </el-table>
       <!-- 分页区域 -->
       <el-pagination
         @size-change="handleSizeProduct"
         @current-change="handleCurrentProduct"
         layout="total, sizes, prev, pager, next, jumper"
         :page-sizes="[20, 30, 50, 100]"
         :total="productTotal"
         :page-size="pageList.pageSize"
         :current-page="pageList.current"
         style="text-align: center; margin-top: 15px"
       ></el-pagination>
       <span slot="footer">
         <el-button @click="addGoodVisible=false">取消</el-button>
         <el-button type="primary" @click="handleConfirmGood">确认</el-button>
       </span>
     </el-dialog>
     <!-- 详情 -->
     <el-dialog
       width="60%"
       v-loading='dialogLoading'
       :visible.sync="orderDetailVisible"
     >
       <div slot="title" style="display:flex">
        <h3 style="margin-right:20px">{{detailInfo.obNo}}</h3>
        <span v-show="detailInfo.status=='toSubmit'" class="status red">待提交</span>
        <span v-show="detailInfo.status=='toOut'" class="status red">待出库</span>
        <span v-show="detailInfo.status=='finished'" class="status red">已完成</span>
        <span v-show="detailInfo.status=='revoked'" class="status red">已撤回</span>
        <div style="flex: 1; text-align:right;margin-right:50px;height:14px">
          <!-- 待提交 -->
          <template v-if="detailInfo.status=='toSubmit'">   
            <el-dropdown style="margin-right:10px" trigger="click" placement="bottom">
              <el-button>操作<i class="el-icon-arrow-down"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="handleToSubmitDetail('delete', detailInfo.obNo, detailInfo.id)">删除</el-dropdown-item>
                <el-dropdown-item @click.native="handleToSubmitDetail('print', detailInfo.obNo, detailInfo.id)">打印</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <el-button @click="handleToSubmitDetail('edit', detailInfo.obNo, detailInfo.id)">编辑</el-button> -->
            <el-button @click="handleToSubmitDetail('submitAndOut', detailInfo.obNo, detailInfo.id)">提交并出库</el-button>
            <el-button type="primary" @click="handleToSubmitDetail('submit', detailInfo.obNo, detailInfo.id)">提交</el-button>
          </template>
          <!-- 待出库 -->
          <template v-if="detailInfo.status=='toOut'">
            <el-button @click="handleToOutDetail('print', detailInfo.obNo, detailInfo.id)">打印</el-button>
            <el-button @click="handleToOutDetail('delete', detailInfo.obNo, detailInfo.id)">删除</el-button>
            <el-button type="primary" @click="handleToOutDetail('out', detailInfo.obNo, detailInfo.id)">确认出库</el-button>
          </template>
          <!-- 已完成 -->
          <template v-if="detailInfo.status=='finished'">
            <el-button @click="handleFinished('print', detailInfo.obNo, detailInfo.id)">打印</el-button>
            <el-button @click="handleFinished('revoke',detailInfo.obNo, detailInfo.id)">撤销</el-button>
            <el-button type="primary" @click="handleFinished('costCollection')">成本补录</el-button>
          </template>
          <!-- 已撤销 -->
          <template v-if="detailInfo.status=='revoked'">
            <el-button @click="handleRevoked('close')">关闭</el-button>
            <el-button type="primary" @click="handleRevoked('print')">打印</el-button>
          </template>
        </div>
       </div>
       <div style="padding: 0 20px">
         <el-steps :space="340" :active="stepNumber" finish-status="success">
          <el-step title="创建">
            <p slot="description">
              {{detailInfo.createByName}}-{{detailInfo.createTime}}
            </p>
          </el-step>
          <el-step title="提交">
            <p slot="description">
              {{detailInfo.submitByName}}-{{detailInfo.submitTime}}
            </p>
          </el-step>
          <el-step title="出库">
            <p slot="description">
              {{detailInfo.outByName}}-{{detailInfo.outTime}}
            </p>
          </el-step>
          <el-step title="完成">
            <p slot="description">
              {{detailInfo.outByName}}-{{detailInfo.outTime}}
            </p>
          </el-step>
          <el-step title="撤销">
            <p slot="description">
             {{detailInfo.revokeByName}}-{{detailInfo.revokeTime}}
            </p>
          </el-step>
         </el-steps>
         <el-form style="margin-top:30px" ref="form" :inline='true' :model="form" label-width="120px">
          <el-form-item label="出库仓库：" style="width:15%">
            {{detailInfo.outStorageName}}
          </el-form-item>
          <el-form-item label="出库类型" style="width:15%">
            {{detailInfo.typeName}}
          </el-form-item>
          <span v-show="detailInfo.type=='FBAOut'||detailInfo.type=='otherOut'?true:false">
            <el-form-item label="目的仓库" style="width: 15%">
              {{detailInfo.outStorageName}}
            </el-form-item>
          </span>
          <span v-show="detailInfo.type=='returnOut'?true:false">
            <el-form-item label="供应商" style="width:15%">
            {{detailInfo.supplierName}}
            </el-form-item>
            <el-form-item style="width:15%">
            <span slot="label">采购单号
              <el-tooltip placement="top">
                <div slot="content">
                  填入采购单号, 系统将溯源采购单价, 并将退货数量自动同步至采购单详情
                </div>
                <i class="iconfont icon-wenhao"></i>
              </el-tooltip>
            </span>
            ¥{{detailInfo.purchaseNo}}
            </el-form-item>
            <el-form-item style="width:15%">
            <span slot="label">退运货费
              <el-tooltip placement="top">
                <div slot="content">
                  若退货运费由我方付请在此处记录
                </div>
                <i class="iconfont icon-wenhao"></i>
              </el-tooltip>
            </span>
            ¥{{detailInfo.freight}}
            </el-form-item>
            <el-form-item label="其他费用：" style="width:15%">
             ¥{{detailInfo.otherFee}}
            </el-form-item>
            <el-form-item style="width: 15%">
              <span slot="label">费用分配方式
                <el-tooltip placement="top">
                  <div slot="content">
                    选中按金额或按数量分配后, 退货费用(运费, 按其他费用)按照采购单算入库存成本, 选中不分配则不计算
                  </div>
                  <i class="iconfont icon-wenhao"></i>
                </el-tooltip>
              </span>
              {{detailInfo.freightShareTypeName}}
            </el-form-item>
            <el-form-item label="采购员" style="width:15%">
            {{detailInfo.purchaseByName}}
            </el-form-item>
          </span>
          <el-form-item label="备注" style="width: 30%">
            {{detailInfo.tips}}
          </el-form-item>
         </el-form>
         <el-tabs v-model="detailCard" type="card" @tab-click="handleClick">
           <el-tab-pane label="出库产品" name="product">
             <el-table
              border
              :data="detailInfo.itemList"
              show-summary
              :cell-style="{ fontSize: '13px', padding: '7px 0' }"
              :header-cell-style="{
                fontSize: '13px',
                padding: '9px 0',
                marginTop: '10px',
                background: '#FAFAFA'
              }">
               <el-table-column label="图片" align="center">
                 <template slot-scope="scope">
                   <Picture :url='scope.row.imageUrl' :width='40'></Picture>
                 </template>
               </el-table-column>
               <el-table-column label="品名/SKU" align="center">
                 <template slot-scope="scope">
                   <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                   <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                 </template>
               </el-table-column>
               <el-table-column label="FNSKU/店铺" prop="" align="center">
                 <template slot-scope="scope">
                   <p class="oneline">{{'无字段'}}</p>
                   <p class="oneline">{{'无字段'}}</p>
                 </template>
               </el-table-column>
               <el-table-column label="单价(¥)" prop="unitPrice" align="center"></el-table-column>
               <el-table-column label="单位费用(¥)" prop="unitCost" align="center"></el-table-column>
               <el-table-column label="单位出库成本(¥)" align="center">
                 <template slot-scope="scope">
                   {{scope.row.unitPrice+scope.row.unitCost}}
                 </template>
               </el-table-column>
               <el-table-column label="可用出库量" prop="goodQuantity" align="center"></el-table-column>
               <el-table-column label="次品出库量" prop="badQuantity" align="center"></el-table-column>
               <el-table-column label="货值" align="center">
                 <template slot-scope="scope">
                   {{(scope.row.goodQuantity+scope.row.badQuantity)*scope.row.unitPrice}}
                   <!--  -->
                 </template>
               </el-table-column>
               <el-table-column label="费用" prop="" align="center">
                 <template slot-scope="scope">
                   {{(scope.row.goodQuantity+scope.row.badQuantity)*scope.row.unitCost}}
                 </template>
               </el-table-column>
               <el-table-column label="出库成本(¥)" prop="" align="center">
                 <template slot-scope="scope">
                   {{(scope.row.goodQuantity+scope.row.badQuantity)*scope.row.unitCost*scope.row.unitPrice}}
                 </template>
               </el-table-column>
             </el-table> 
           </el-tab-pane>
           <el-tab-pane label="操作记录" name="record">
             <!--操作记录数据 -->
             <el-table 
              border 
              :data="operationalData"
              :cell-style="{ fontSize: '13px', padding: '7px 0' }"
              :header-cell-style="{
                fontSize: '13px',
                padding: '9px 0',
                marginTop: '10px',
                background: '#FAFAFA'
              }">
               <el-table-column label="操作时间" prop="createTime" align="center"></el-table-column>
               <el-table-column label="操作人" prop="createByName" align="center"></el-table-column>
               <el-table-column label="操作类型" prop="operateTypeName" align="center"></el-table-column>
               <el-table-column label="操作详情" prop="details" align="center"></el-table-column>
             </el-table>
           </el-tab-pane>
         </el-tabs>
       </div>
        <span slot="footer">
          <el-button @click="orderDetailVisible=false">取消</el-button>
          <el-button type="primary" @click="orderDetailVisible=false">确认</el-button>
        </span>
     </el-dialog>
     <!-- 导入产品 -->
     <el-dialog
      width="700px"
      title="导入产品"
      :visible.sync="ImportProduct"
      :destroy-on-close='true'
     >
      <div>
       <el-upload
          class="upload-demo"
          :action="action"
          :headers="mytoken"
          :on-success="uploadSuccess"
          :on-error="uploadError"
          multiple
          :on-exceed="handleExceed"
          :file-list="fileList"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <el-button @click.native="downTemplate" slot="tip" type="text" style="margin-left:15px" class="el-upload__tip">下载导入模板</el-button>
       </el-upload>
       <ul style="line-height:25px;margin-top:20px">
         <li>支持导入格式为xlsx的文件(手动修改文件后缀无效)</li>
         <li>模版中的表头不可更改，不可删除</li>
         <li>数据记录不要超过500条</li>
         <li>单价小数点后第三位四舍五入</li>
         <li>同一个SKU存在多条记录，以第一条为准</li>
       </ul><br><br>
      </div>
      <span slot="footer">
        <el-button @click="ImportProduct=false">取 消</el-button>
        <el-button @click="ImportProduct=false">确 定</el-button>
      </span>
     </el-dialog>
   </div>
</template>

<script>
import {
  pageInit,   // 初始化
  addInit,    // 新增初始化
  handleAdd,  // 新增
  outOrderlistPage,   // 查询分页
  listPage,  // 商品查询分页
  OutNoteDetail,  // 查询出库详情
  batchUpdate,    // 批量操作 
  updateInit,     // 编辑初始化
  handleEdite,    // 编辑事件
  updateTips,     // 更新备注
  operateRecordList,   // 操作记录
}from '@/api/InventoryManage/outBoundManual.js'

export default {
  data() {
    var token = window.sessionStorage.getItem('token')
    return {
     total: 0,  // 总条数
     title: "",
     type: 0,   // 1 表示新增  2 表示编辑
     productTotal: 0,  // 总条数
     activeName: "all",
     detailCard: "product",
     operationalData: [],
     pageInfo: {
       timeType: "create_time",    // 时间类型 
       time: "",        // 开始/结束时间
       storageType: "", // 入库类型
       keyword: "",     // 关键词
       storageId: "",   // 仓库Id
       searchType: "ob_no",  // 搜索类型
       startTime: "",
       endTime: "",
       current: 1,
       pageSize: 20
     },
     formDataList: {
       outboundId: "",    // 出库Id
       outboundType: "",  // 出库类型
       toStorageId: "",   // 目的仓库
       purchaseOrder: "", // 采购单号
       returnFreight: "", // 退货运费
       otherFreight: "",  // 其他费用
       allocation: "",    // 费用分配方式
       buyer: "",         // 购买人
       supplierId: "",   // 供货商
       remarks: "",    // 备注
     },
     pageList: {
       keyword: "",
       current: 1,
       pageSize: 20
     },
     mytoken: { token:token, storageId:'' },
     ImportProduct: false,
     addGoodVisible: false,  // 添加商品
     orderDetailVisible: false,  // 订单详情
     tabName: "",  
     datetime: "",   // 选择时间
     outTypeList: [],
     storageIdList: [],   // 全部仓库
     searchTypeList: [],  // 查询下拉框
     tableList: [],       // table  表格数据
     addOutOrderVisible: false,
     storageList: [],  //  出库仓库
     outTypeList: [],  //  出库类型
     toWarehouseList: [],  // 目的仓库
     supplierList: [],     // 供应商
     costAllocationList: [],
     buyerList: [],  // 采购员
     productList: [],  // 产品集合
     productDataList: [],  // 产品数据集合
     multipleSelection: [],
     multipleProduct: [],  // 外层的
     detailInfo: {},
     dialogLoading: false,  
     waitOutCount: 0,
     waitSubmitCount: 0,
     editDetail: {},
     editId: "",
    }
  },
  created() {
    this.getPageInit();   // 初始化数据
    this.handleSearch("");
    this.getAddInit();    // 新增初始化
  },
  watch: {
    datetime(value) {
      if(value) {
        this.pageInfo.startTime = value[0];
        this.pageInfo.endTime = value[1];
      }else{
        this.pageInfo.startTime = "";
        this.pageInfo.endTime = "";
      }
    }
  },
  computed: {
    stepNumber() {
      switch(this.detailInfo.status) {
        case 'create':
        return 1
        case 'toSubmit':
        return 2
        case 'toOut':
        return 3
        case 'finished':
        return 4
        case 'revoked':
        return 5
      }
    },
    action() {
      return process.env.VUE_APP_API_URL+'/api/warehouseOutNote/importProductWhenAddOrUpdate'
    }
  },
  methods: {
    getPageInit() {
      pageInit()
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.searchTimeTypeList = res.data.data.searchTimeTypeList;   // 时间类型
          this.storageIdList = res.data.data.storageList;     // 全部仓库
          this.outTypeList = res.data.data.outTypeList;       // 
          this.searchTypeList = res.data.data.searchTypeList; // 下拉框搜索 
        }
      })
    },
    handleClick(tab) {
      this.tabName = tab.name;
      if(tab.name =='all') {         // 所有状态
        this.handleSearch('');
      }else if(tab.name =='toSubmit') {   // 待提交
        this.handleSearch("toSubmit");
      }else if(tab.name =='toOut') {      // 待出库
        this.handleSearch("toOut");
      }else if(tab.name =='finished') {   // 已完成
        this.handleSearch("finished");
      }else if(tab.name =='revoked') {    // 已撤销
        this.handleSearch("revoked");
      }
    },
    // 重置
    reset() {
      this.pageInfo.timeType = "";
      this.pageInfo.storageType = "";
      this.pageInfo.storageId = "";
      this.pageInfo.keyword = "";
      this.pageInfo.searchType = "";
    },
    // 表格合并单元格
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (row.itemList!=undefined) {
        if (columnIndex == 1) {
          return [1, 7];
        } else {
          return [1, 1];
        }
      }else{
        [0,1]
      }
    },
    // 新增初始化
    getAddInit() {
      addInit()      // 新增初始化
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.storageList = res.data.data.storageList;  // 出库仓库
          this.outTypeList = res.data.data.outTypeList;  // 出库类型
          this.toWarehouseList = res.data.data.toStorageList;  // 目的仓库
          this.costAllocationList = res.data.data.freightShareTypeList,  // 分配方式
          this.buyerList = res.data.data.userList;   // 采购员
          this.supplierList =res.data.data.supplierList;  // 供应商
        }
      })
    },
    // 查询
    handleSearch(status) {
      let params = {
        current: this.pageInfo.current,    //	当前页
        timeType: this.pageInfo.timeType,  // 时间搜索类型
        beginTime: this.pageInfo.startTime,  // 	开始时间
        endTime: this.pageInfo.endTime,   // 结束时间
        type: this.pageInfo.storageType,
        storageId: this.pageInfo.storageId,
        searchType: this.pageInfo.searchType,
        keyword: this.pageInfo.keyword,
        status: status=='all'||status==''?'':status,
        pageSize: this.pageInfo.pageSize
      }
      outOrderlistPage(params)
      .then(res=> {
        this.tableList = [];   // 数据清空
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.tableList = res.data.values;
          this.total = res.data.pageInfo.total;
          this.waitSubmitCount = res.data.mapInfo.submitSize;
          this.waitOutCount = res.data.mapInfo.warehouseSize;
        }
      })
    },
    // 添加出库单
    handleOutbound() {
      this.title = "0";
      this.type = '1';
      this.formDataList.outboundId = "";   // 出库仓库
      this.formDataList.outboundType = ""; // 出库类型
      this.formDataList.toStorageId = "";  // 目的仓库
      this.formDataList.supplierId = "";  // 供应商
      this.formDataList.returnFreight = "";  // 退货运费
      this.formDataList.otherFreight = "";  // 其他运费
      this.formDataList.allocation = "";  // 费用分配方式
      this.formDataList.buyer = "";  // 采购员
      this.formDataList.remarks = "";  // 备注
      this.productList = [];
      this.addOutOrderVisible = true;
    },
    handleSelectionChange(val) {
      this.multipleProduct = val;
      console.log(this.multipleProduct, '最外层复选框多选数据');
    },
    // 操作事件
    handleBatchUpdate(operateType,obNo,id) {
      if(operateType=='submit') {
        this.$confirm(`确定提交${obNo}? 提交后不可再次编辑`, '提交?', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
        .then(() => {
          let ids = [];
          ids.push(id);
          let data = {
            ids: ids,
            operateType: operateType
          } 
          batchUpdate(data)
          .then(res=> {
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.$message.success(res.data.message);
              this.handleSearch(this.tabName);
            }
          }) 
        })
      }else if(operateType=='submitAndOut') {
        this.$confirm(`确定提交并出库${obNo}?出库后, 出库单状态变更为[已完成]`, '提交并出库', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        })
        .then(() => {
          let ids = [];
          ids.push(id);
          let data = {
            ids: ids,
            operateType: operateType
          }
          batchUpdate(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.handleSearch(this.tabName);
             }
           })
        })
      }else if(operateType=='delete') {
        this.$confirm(`确定删除${obNo}?删除后不可恢复`, '删除?', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        })
        .then(() => {
          let ids = [];
          ids.push(id);
          let data = {
            ids: ids,
            operateType: operateType
          }
          batchUpdate(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.handleSearch(this.tabName);
             }
           })
        })
      }else if(operateType=='revoke') {
        this.$confirm(`确定撤销${obNo}?撤销后不可恢复`, '撤销?', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        })
        .then(() => {
          let ids = [];
          ids.push(id);
          let data = {
            ids: ids,
            operateType: operateType
          }
          batchUpdate(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.handleSearch(this.tabName);
             }
           })
        })  
      }else if(operateType=='out') {    // 确认出库
        this.$confirm(`确定出库${obNo}?出库后, 出库单状态变更为[已完成]`, '确认出库?', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        })
        .then(() => { 
          let ids = [];
          ids.push(id);
          let data = {
            ids: ids,
            operateType: operateType
          }
          batchUpdate(data)
          .then(res=> {
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.$message.success(res.data.message);
              this.handleSearch(this.tabName);
            }
          })
        })
      }
    },
    // 待提交详情
    handleToSubmitDetail(operateType,obNo,id) {
      if(operateType=='delete') {      //  删除操作
        this.$confirm(`确定删除${obNo}?删除后不可恢复`, '删除?', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        })
        .then(() => {
          let ids = [];
          ids.push(id);
          let data = {
            ids: ids,
            operateType: operateType
          }
          batchUpdate(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.orderDetailVisible = false;
               this.handleSearch(this.tabName);
             }
           })
        })
      }else if(operateType=='print') {   // 打印

      }else if(operateType=='submitAndOut') {    // 提交并出库
        this.$confirm(`确定提交并出库${obNo}?出库后, 出库单状态变更为[已完成]`, '提交并出库', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        })
        .then(() => {
          let ids = [];
          ids.push(id);
          let data = {
            ids: ids,
            operateType: operateType
          }
          batchUpdate(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.orderDetailVisible = false;
               this.handleSearch(this.tabName);
             }
           })
        })
      }else if(operateType=='submit') {      // 提交
        this.$confirm(`确定提交${obNo}? 提交后不可再次编辑`, '提交?', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
        .then(() => {
          let ids = [];
          ids.push(id);
          let data = {
            ids: ids,
            operateType: operateType
          } 
          batchUpdate(data)
          .then(res=> {
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.$message.success(res.data.message);
              this.orderDetailVisible = false;
              this.handleSearch(this.tabName);
            }
          }) 
        })
      }
    },
    // 待出库
    handleToOutDetail(operateType,obNo,id) {
       if(operateType=='print') {   // 打印

       }else if(operateType=='delete') {   // 删除
          this.$confirm(`确定删除${obNo}?删除后不可恢复`, '删除?', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确认',
            cancelButtonText: '取消'
          })
          .then(() => {
            let ids = [];
            ids.push(id);
            let data = {
              ids: ids,
              operateType: operateType
            }
            batchUpdate(data)
             .then(res=> {
               if(res.data.code == 500) {
                 this.$message.error(res.data.message);
               }else {
                 this.$message.success(res.data.message);
                 this.orderDetailVisible = false;
                 this.handleSearch(this.tabName);
               }
             })
          }) 
       }else if(operateType=='out') {      // 确认出库
         this.$confirm(`确定出库${obNo}?出库后, 出库单状态变更为[已完成]`, '确认出库?', {
           distinguishCancelAndClose: true,
           confirmButtonText: '确认',
           cancelButtonText: '取消'
         })
         .then(() => { 
           let ids = [];
           ids.push(id);
           let data = {
             ids: ids,
             operateType: operateType
           }
           batchUpdate(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.handleSearch(this.tabName);
             }
           })
         })
       }
    },
    // 已完成
    handleFinished(operateType,obNo,id) {
      if(operateType=='print') {   // 打印

      }else if(operateType=='revoke') {    // 撤销
        console.log('011022002');
        this.$confirm(`确定撤销${obNo}?撤销后不可恢复`, '撤销?', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        })
        .then(() => {
          let ids = [];
          ids.push(id);
          let data = {
            ids: ids,
            operateType: operateType
          }
          batchUpdate(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.orderDetailVisible = false;
               this.handleSearch(this.tabName);
             }
           })
        })
      }else if(operateType=='costCollection') {    // 成本补录

      }
    },
    // 已撤销详情
    handleRevoked(operateType) {
      if(operateType=='close') {
        this.orderDetailVisible = false;
      }else if(operateType=='print') {

      }
    },
    // 编辑事件
    getUpdateInit(id) {
      this.title = "1";
      this.type = '2';
      this.editId = id;
      updateInit(id)
       .then(res=> {
         console.log(res, 'kkkkkkkkkkkkk');
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.editDetail = res.data.data;
           this.formDataList.outboundId = res.data.data.outStorageId;  // 出库仓库
           this.formDataList.outboundType = res.data.data.type;   //  出库类型
           this.formDataList.toStorageId = res.data.data.toStorageId;   // 目的仓库
           this.formDataList.supplierId = res.data.data.supplierId;   //  供应商
           this.formDataList.returnFreight = res.data.data.freight;   //  退货运费
           this.formDataList.otherFreight = res.data.data.otherFee;   //  其他费用
           this.formDataList.allocation = res.data.data.freightShareType;  // 费用分配方式
           this.formDataList.buyer = res.data.data.purchaseBy;   // 采购员
           this.formDataList.remarks = res.data.data.tips;    // 备注
           res.data.data.itemList.forEach(item=> {
             let object = {
               imageUrl: item.imageUrl,
               title: item.title,
               sku: item.sku,
               goodQuantity: item.leftGoodQuantity,
               badQuantity: item.leftBadQuantity,
               available: item.goodQuantity,  // 可用出库量
               defective: item.badQuantity,   // 次品出库量
               productId: item.productId
             }
             this.productList.push(object);
           })
         }
       })
      this.addOutOrderVisible = true;
    },  
    // 待提交批量操作事件
    handleToSubmit(operateType) {
      if(!this.multipleProduct.length) {
        this.$message.error('请选择要操作的出库单');
        return false;
      }
      if(operateType=='submit') {     // 批量提交
        this.$confirm(`共${this.multipleProduct.length}个出库单, 确认提交?提交后不可在编辑`, '批量提交?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = this.multipleProduct.map(t=> t.id);
          let data = {
            ids: ids,
            operateType: "submit"
          }
          batchUpdate(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.handleSearch(this.tabName);
             }
           })
        })
      }else if(operateType=='submitAndOut') {   // 批量提交并出库
        this.$confirm(`共${this.multipleProduct.length}个出库单, 确认提交并出库?出库后, 出库单状态变更为[已完成]`, '批量提交并出库?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res=> {
          let ids = this.multipleProduct.map(t=> t.id);
          let data = {
            ids: ids,
            operateType: "submitAndOut"
          }
          batchUpdate(data)
          .then(res=> {
            console.log(res);
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.$message.success(res.data.message);
              this.handleSearch(this.tabName);
            }
          })
        })
      }else if(operateType=='delete') {       //  批量删除
        this.$confirm(`共${this.multipleProduct.length}个出库单, 确认删除?删除后不可恢复`, '批量删除?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res=> {
          let ids = this.multipleProduct.map(t=> t.id);
          let data = {
            ids: ids,
            operateType: "delete"
          }
          batchUpdate(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.handleSearch(this.tabName);
             }
           })
        })
      }
    },
    // 待出库批量操作
    handleBatchOut(operateType) {
      if(!this.multipleProduct.length) {
        this.$message.error('请选择要操作的出库单');
        return false;
      }
      if(operateType=='out') {
        this.$confirm(`共${this.multipleProduct.length}个出库单, 确认出库后,出库单状态变更为[已完成]?`, '批量确认出库?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = this.multipleProduct.map(t=> t.id);
          let data = {
            ids: ids,
            operateType: operateType
          }
          batchUpdate(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.handleSearch(this.tabName);    
             }
           })
        })
      }else if(operateType=='delete') {
        this.$confirm(`共${this.multipleProduct.length}个出库单, 确认删除,`, '批量删除?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = this.multipleProduct.map(f=> f.id);
          let data = {
            ids: ids,
            operateType: operateType
          }
          batchUpdate(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.handleSearch(this.tabName);
             }
           })
        })
      }else if(operateType=='submit') {
        this.$confirm(`共${this.multipleProduct.length}个出库单, 确认提交?提交后不可在编辑`, '批量提交?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = this.multipleProduct.map(t=> t.id);
          let data = {
            ids: ids,
            operateType: "submit"
          }
          batchUpdate(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.handleSearch(this.tabName);
             }
           })
        })
      }
    },
    // 查询商品信息
    getProductList() {
      let params = {
        isFilterZero: 0,
        storageId: this.formDataList.outboundId,
        keyword: this.pageList.keyword,
        current: this.pageList.current,
        pageSize: this.pageList.pageSize
      }
      listPage(params)
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.productDataList = res.data.values;
          this.productTotal = res.data.pageInfo.total;
        }
      })
    },
    handleAddProduct() {
      if(!this.formDataList.outboundId) {
        this.$message.error('请选择出库仓库');
        return false;
      }
      this.getProductList();
      this.addGoodVisible = true;
    },
    handleSelectionProduct(val) {
      this.multipleSelection = val;
    },
    // 确认选择商品
    handleConfirmGood() {
      this.productList = [];
      this.multipleSelection.forEach(data=> {
        let object = {
          imageUrl: data.imageUrl,
          title: data.title,
          sku: data.sku,
          goodQuantity: data.goodQuantity,
          available: 0,   //  可用量
          badQuantity: data.badQuantity,   
          delfective: 0,
          productId: data.productId
        }
        this.productList.push(object);
      })
      this.addGoodVisible = false;
    },
    handleSizeProduct(newSize) {
      this.pageList.pageSize = newSize;
      this.getProductList();
    },
    handleCurrentProduct(newCurrent) {
      this.pageList.current = newCurrent;
      this.getProductList();
    },
    handleRemove(index) {
      this.productList.splice(index, 1);
    },
    // 确认事件
    handleSubmit(statusType, type) {
      if(!this.formDataList.outboundId) {
       this.$message.error('请选择出库仓库');
       return false 
      }
      if(!this.formDataList.outboundType) {
        this.$message.error('请选择出库类型');
        return false;
      }
      if(this.formDataList.outboundType=='returnOut') {
        if(!this.formDataList.supplierId) {
          this.$message.error('请选择供应商');
          return false;
        }
        if(!this.formDataList.allocation) {
          this.$message.error('请选择费用分配方式');
          return false;
        }
        if(!this.formDataList.buyer) {
          this.$message.error('请选择采购员');
          return false;
        }
      }
      for (let index = 0; index<this.productList.length; index++) {
        if(!this.productList[index].available) {
          this.$message.error('请填写可用出库量');
          return false;
        }
        if(!this.productList[index].delfective) {
          this.$message.error('请填写次品出库量');
          return false;
        }
      }
      for (let data = 0; data<this.productList.length; data++) {
        if(this.productList[data].available>this.productList[data].goodQuantity) {
          this.$message.error('商品库存不够');
          return false;
        }
        if(this.productList[data].delfective>this.productList[data].badQuantity) {
          this.$message.error('商品库存不够');
          return false;
        }
      }
      let productList = [];
      this.productList.forEach(item=> {
        let object = {
          badQuantity: parseInt(item.delfective),   // 次品出库量
          goodQuantity: parseInt(item.available),  // 可用出库量
          productId: item.productId,
          sku: item.sku 
        }
        productList.push(object);
      })
      let data = {
        freight: parseInt(this.formDataList.returnFreight),   // 退回运费
        freightShareType: this.formDataList.allocation,  // 运费分摊方式
        operateType: statusType,     // 各种状态
        otherFee: parseInt(this.formDataList.otherFreight),  // 其他费用
        outStorageId: this.formDataList.outboundId,    // 出库仓库
        purchaseBy: this.formDataList.buyer,  // 采购员
        toStorageId: this.formDataList.toStorageId,  // 目的仓库
        type: this.formDataList.outboundType,        // 出库类型
        tips: this.formDataList.remarks,   // 备注
        supplierId: this.formDataList.supplierId,  // 供货商
        itemList: productList,
        id: type == 1?null:this.editId
      }
      if(type == 1) {
        handleAdd(data)
        .then(res=> {
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.$message.success(res.data.message);
            this.addOutOrderVisible = false;
            this.handleSearch(this.tabName)
          }
        })
      }else if(type == 2) {
        handleEdite(data)
        .then(res=> {
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.$message.success(res.data.message);
            this.addOutOrderVisible = false;
            this.handleSearch(this.tabName)
          }
        })
      }
    },
    handleCommand1(command) {
      if(command=="0") {        // 批量提交
        if(!this.multipleProduct.length) {
          this.$message.error('请选择需要操作的出库单');
          return false;
        }
        // 待提交状态
        let status = this.multipleProduct.map(g=> g.status);
        const test = (ele) => {
          return ele == 'toSubmit';
        };
        let flag = status.every(test);
        if(flag) { 
          this.$confirm(`共${this.multipleProduct.length}个出库单, 确认提交?提交后不可再次编辑`, '批量提交?', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let ids = this.multipleProduct.map(v=> v.id);
            let data = {
              ids: ids,
              operateType: "submit"
            }
            batchUpdate(data)
            .then(res=> {
              if(res.data.code == 500) {
                this.$message.error(res.data.message);
              }else {
                this.$message.success(res.data.message);
                this.handleSearch(this.tabName);
              }
            })
          })
        }else {
          this.$message.error('请选择待提交状态的出库单')
        }
      }else if(command=="1") {       // 批量确认出库
        if(!this.multipleProduct.length) {
          this.$message.error('请选择需要操作的出库单');
          return false;
        }
        let status = this.multipleProduct.map(g=> g.status);
        const test = (ele) => {
          return ele == 'toOut';
        };
        let flag = status.every(test);
        if(flag) {
         this.$confirm(`共${this.multipleProduct.length}个出库单, 确认出库?确定出库后, 出库单状态变更为[已完成]`, '批量确认出库?', {
           confirmButtonText: '确定',
           cancelButtonText: '取消',
           type: 'warning'
         }).then(() => {
           let ids = this.multipleProduct.map(v=> v.id);
           let data = {
             ids: ids,
             operateType: "out"
           }
           batchUpdate(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.handleSearch(this.tabName);
             }
           })
         }) 
        }else {
          this.$message.error('请选择待出库状态的出库单')
        }
      }else if(command=="2") {   // 批量提交并出库
        if(!this.multipleProduct.length) {
          this.$message.error('请选择需要操作的出库单');
          return false;
        }
        // 待提交状态
        let status = this.multipleProduct.map(g=> g.status);
        const test = (ele) => {
          return ele == 'toSubmit';
        };
        let flag = status.every(test);
        if(flag) {
          this.$confirm(`共${this.multipleProduct.length}个出库单, 确认提交?提交后不可再次编辑`, '批量提交?', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let ids = this.multipleProduct.map(v=> v.id);
            let data = {
              ids: ids,
              operateType: "submit"
            }
            batchUpdate(data)
            .then(res=> {
              if(res.data.code == 500) {
                this.$message.error(res.data.message);
              }else {
                this.$message.success(res.data.message);
                this.handleSearch(this.tabName);
              }
            })
          })
        }else {
          this.$message.error('请选择待待提交状态的出库单')
        }
      }else if(command=="3") {   // 批量删除
        if(!this.multipleProduct.length) {
          this.$message.error('请选择需要操作的出库单');
          return false;
        }
        // 待提交状态
        let status = this.multipleProduct.map(g=> g.status);
        const test = (ele) => {
          return ele == 'toSubmit' || ele == 'toOut';
        };
        let flag = status.some(test);
        if(flag) {
          this.$confirm(`共${this.multipleProduct.length}个出库单, 确认删除?删除后不可恢复`, '批量删除?', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let ids = this.multipleProduct.map(v=> v.id);
            let data = {
              ids: ids,
              operateType: "delete"
            }
            batchUpdate(data)
            .then(res=> {
              if(res.data.code == 500) {
                this.$message.error(res.data.message);
              }else {
                this.$message.success(res.data.message);
                this.handleSearch(this.tabName);
              }
            })
          })

        }else {

        }
      }
    },
    // 批量打印出库单
    handlePrintOrder(operateType) {   
      if(!this.multipleProduct.length) {
        this.$message.error('请选择需要打印的出库单');
        return false;
      }
      if(operateType=='') {

      }else if(operateType=='toSubmit') {

      }else if(operateType=='toOut') {

      }else if(operateType=='finished') {

      }else if(operateType=='revoked') {

      }
    },  
    getDetail(id) {        // 详情
      this.dialogLoading = true;
      OutNoteDetail(id)
      .then(res=> {
        console.log(res, '详情接口');
        this.dialogLoading = false;
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.detailInfo = res.data.data;   // 获取详情数据
          this.getOperateRecord(id);
        }
      })
      this.orderDetailVisible = true;
    }, 
    // 获取操作记录数据
    getOperateRecord(id) {
      let params = {
        objectId: id,
        operateObject: "out"
      }
      operateRecordList(params)
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.operationalData = res.data.data;
        }
      })
    },      
    handleSizeChange(newSize) {
      this.pageInfo.pageSize = newSize;
    },
    handleCurrentChange(newCurrent) {
      this.pageInfo.current = newCurrent;
    },
    handleUpdateTip(row) {
      console.log(row);
      this.$prompt('请输入备注', '编辑', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: row.tips,
      }).then(({ value }) => {
        let data = {
          id: row.id,
          tips: value
        }
        updateTips(data)
         .then(res=> {
           console.log(res);
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);
             this.handleSearch(this.tabName);
           }
         })
      })
    },
    changeStorage(val) {
      console.log(val);
      this.mytoken.storageId = val;
    },
    // 导入产品
    handleImport() {
      if(!this.formDataList.outboundId) {
        this.$message.error('请选择出库仓库');
        return false;
      }
      this.ImportProduct = true;
    },
    downTemplate() {
      window.location.href='出库单导入模板.xlsx'
    },
    uploadSuccess(res) {
      if(res.code == 500) {
        this.$message.error(res.message);
      }
    },
    uploadError(res) {
      console.log(res);
    }
  },  
}
</script>

<style lang="scss" scoped>
.Box{
  width: 100%;
  height: calc(100% - 68px);
  display: flex;
  flex-direction: column;
  .content{
      flex: 1;
      overflow: auto;
      padding-top: 10px;
  }
}
.tableMenu{
  display: flex;
  align-items: center;
  >div{
      margin-right:20px
  }
}
.itemOption {
  >div{
    >span:nth-of-type(1){
     color: #999999;
    }
  }
}
.noPic{
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size:8px;
  color: #909399;
  margin-right: 15px;
  span{
    display: block;
    background: #EBEEF5;
    width: 100%;
    height: 100%;
  }
}
// 表格样式
::v-deep .outTable .cell .el-table__expand-icon{
    display: none;
}
::v-deep .outTable .el-table__row--level-1{
  .cell{
    >.el-checkbox{
        display: none;
    }
  }
}
::v-deep .outTable .el-table__row--level-0{
    background:#EBEEF5;
}
::v-deep .outTable .cell .el-table__indent{
    display: none;
}
::v-deep .outTable .cell .el-table__placeholder{
    display: none;
}
.oneline {
  white-space: nowrap;  
  text-overflow:ellipsis;
  overflow:hidden;
}
.status{
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  padding: 0;
}
.orange{
  color: orange !important;
  border-color: orange;
}
.red{
  color: red !important;
  border-color: red ;
}
.green{
  color: #91D876 !important;
  border-color: #91D876;
}
</style>