import request from '@/utils/request'

// 查询初始化
export function pageInit() {
   return request({
     url: "/api/warehouseOutNote/pageInit",
     method: "get"
   })
}

// 查询分页
export function outOrderlistPage(params) {
   return request({
     url: "/api/warehouseOutNote/listPage",
     params,
     method: "get"
   })
}

// 新增初始化
export function addInit() {
   return request({
     url: "/api/warehouseOutNote/addInit", 
     method: "get"  
   })
}

// 新增
export function handleAdd(data) {
  return request({
    url: "/api/warehouseOutNote",
    data,
    method: "post"
  })
}

// 查询商品分页(仓库-库存明细模块)
export function listPage(params) {
  return request({
    url: "/api/warehouseDetail/listPage",
    params,
    method: "get"
  })
}

// 根据id 查看详情
export function OutNoteDetail(id) {
  return request({
    url: `/api/warehouseOutNote/${id}`,
    method: "get"
  })
}

// 批量操作(提交,提交并出库,出库,删除,撤销)
export function batchUpdate(data) {
  return request({
    url: "/api/warehouseOutNote/batchUpdate",
    data,
    method: "put"
  })
}

// 编辑初始化
export function updateInit(id) {
  return request({
    url: `/api/warehouseOutNote/updateInit/${id}`,
    method: "get"
  })
}

// 编辑事件
export function handleEdite(data) {
  return request({
    url: "/api/warehouseOutNote",
    data,
    method: "put"
  })
}

// 更新备注
export function updateTips(data) {
  return request({
    url: "/api/warehouseOutNote/updateTips",
    data,
    method: "post"
  })
}

// 操作记录接口
export function operateRecordList(params) {
  return request({
    url: "/api/warehouseOperateRecord/list",
    params,
    method: "get"
  })
}